import React, { useContext, useState, useEffect } from 'react';
import { AuthContext } from '../AuthProvider';

const CurrencyEditWorker = (props: any) => {
    const {currentUser} = useContext(AuthContext);

    const [id] = useState(Math.random().toString().split(".")[1]);
    const [code, setCode] = useState(props.defaultCode || "₩");
    const [price, setPrice] = useState("");
    const [createdBy, setCreatedBy] = useState("");

    useEffect(() => {
        if (code && price) {
            props.setState(price + code)
        }
    }, [props, code, price])
	
	
    return (
        <div className="form-group">
            {props.label &&
                <label htmlFor={id}>{props.label}:</label>
            }
            <div className="input-group mb-3">				
				<input disabled={["worker","delivery","reporter","editor"].indexOf(currentUser?.role)!==-1 && currentUser?.role!=props.createdUserRole} type={props.type || "text"} className="form-control" id={id} defaultValue={props.state} onChange={(e) => props.setState(e.target.value)} />
                {props.append && (
                    <div className="input-group-append">
                        <span className="input-group-text">{props.append}</span>
                    </div>
                )}
				
            </div>
        </div>
    );
}

export default CurrencyEditWorker;