import React, { useContext, useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import firebase from "../../firebase";
import { stats, modes, paymentMethods, deliveryOptions, paymentUtilities } from "../../enums";
import Input from "../elements/Input";
import Select from "../elements/Select";
import SelectRemovePaymentMethod from "../elements/SelectRemovePaymentMethod";
import SelectMode from "../elements/SelectMode";
import CurrencyInput from "../elements/CurrencyInput";
import Modal from "../elements/Modal";
import { AuthContext } from '../AuthProvider';
import Textarea from '../elements/Textarea';
import ButtonInput from '../elements/ButtonInput';
import ReceiverListItem from './ReceiverListItem';
import SenderListItem from './SenderListItem';
import { unstable_batchedUpdates } from 'react-dom';
import ButtonPhoneInput from '../elements/ButtonPhoneInput';


const db = firebase.firestore();

const Create = () => {
    
    const [open, setOpen] = React.useState(false);
    const [isSender, setSender] = React.useState(false);
 
    const handleClose = () => {
        setOpen(false)
    };
 
    const handleOpen = (isSender: boolean) => unstable_batchedUpdates(() => {
        setSender(isSender);
        setOpen(true);
    })

    const {currentUser} = useContext<any>(AuthContext);
    const [status, setStatus] = useState<string>(Object.keys(stats)[0]);
    const [senderName, setSenderName] = useState("");
    const [senderPhoneCode, setSenderPhoneCode] = useState("+82");
    const [senderPhoneNumber, setSenderPhoneNumber] = useState("");
    const [senderPhone, setSenderPhone] = useState("");
    const [senderAddress, setSenderAddress] = useState("Seoul Jung-gu Eulji-ro 44-gil 13 , 203 door");
    const [receiverName, setReceiverName] = useState("");
    const [receiverPhoneCode, setReceiverPhoneCode] = useState("+976");
    const [receiverPhoneNumber, setReceiverPhoneNumber] = useState("");
    const [receiverPhone, setReceiverPhone] = useState("");
    const [receiverAddress, setReceiverAddress] = useState("БЗД 25-р хороо Нарны зам дахь салбар, 88077555");
    const [receiverRegister, setReceiverRegister] = useState("");	
    const [weight, setWeight] = useState(0);
    const [netWeight, setNetWeight] = useState(0);	
    const [price, setPrice] = useState(0);
    const [paymentMethod, setIsPaymentMethod] = useState(Object.keys(paymentMethods)[0]);
    const [hasDelivery, setHasDelivery] = useState(Object.keys(deliveryOptions)[0]);
    const [mode, setMode] = useState(Object.keys(modes)[0]);
    const [departTime, setDepartTime] = useState((new Date()).toJSON().split("T")[0]);
    const [createdBy, setCreatedBy] = useState(currentUser?.phoneNumber);
    const [desc, setDesc] = useState("");
    const [value, setValue] = useState("0");
    const [postfee, setPostfee] = useState(0);
    const [quantity, setQuantity] = useState(0);
    const [tegbeCode, setTegbeCode] = useState("");
    const [orderCode, setOrderCode] = useState("");	
    const [notif, setNotif] = useState("");
    const [paymentUtility, setPaymentUtility] = useState(Object.keys(paymentUtilities)[0]);
    const history = useHistory();
    const [searchResult, setSearchResult] =  useState<any[]>([]);

    useEffect(() => {
        if (currentUser) {
            setCreatedBy(currentUser.phoneNumber);
        }
    }, [currentUser]);

    useEffect(() => {
        if(senderPhoneCode && senderPhoneNumber)
            setSenderPhone(senderPhoneCode + senderPhoneNumber)
    }, [senderPhoneCode, senderPhoneNumber]);

    useEffect(() => {
        if(receiverPhoneCode && receiverPhoneNumber)
            setReceiverPhone(receiverPhoneCode + receiverPhoneNumber)
    }, [receiverPhoneCode, receiverPhoneNumber]);
    
    
//	useEffect(() => {
//		const date = Math.random() < 0.5 ? "01" : "10";
//		setDepartTime((new Date("1999-03-" + date)).toJSON().split("T")[0])
//	}, [mode])

    const create = () => {
		
        const id = (new Date()).toJSON().split("T")[0].replace(/-/g, "").substring(2)+mode.substr(0,1).toUpperCase()+"-"+ Math.random().toString().substr(2, 4);

        db.doc(`packages/${id}`).set({
            status,
            senderName,
            senderName_lowerCase: senderName.toLowerCase(),
            senderPhone,
            receiverName,
            receiverName_lowerCase: receiverName.toLowerCase(),
            receiverPhone,
            weight,
			netWeight,
            price,
            paymentMethod,
            hasDelivery,
            mode,
            departTime,
            senderAddress,
            receiverAddress,
			receiverRegister,
            createdBy,
            desc,
            value,
            postfee,
            quantity,
            notif,
            paymentUtility,
			tegbeCode,
			orderCode,
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        }).then(() => {
            history.push(`/packages/${id}`);
        }).catch(console.error);
    }

    const searchSender = (searchtype: string) => {
        let ps: any[] = [];
        
        if (searchtype === 'senderName'){
            if(!senderName)
                return

            console.log(senderName.toUpperCase())
            console.log(senderName.toLowerCase())
            db.collection("packages").orderBy("senderName_lowerCase").startAt(senderName.toLowerCase()).endAt(senderName.toLowerCase() + "\uf8ff").limit(1000).get().then((data)=>{
                
                ps = data.docs.map((pack: { id: any; data: () => any; }) => ({ id: pack.id, ...pack.data() }));
                setSearchResult(ps)
                handleOpen(true);
            });
            
        }else{
            if(!(senderPhoneCode && senderPhoneNumber))
                return


            db.collection("packages").orderBy("senderPhone").startAt(senderPhoneCode.trim() + senderPhoneNumber.trim()).endAt(senderPhoneCode.trim() + senderPhoneNumber.trim() + "\uf8ff").limit(1000).get().then((data)=>{
                
                ps = data.docs.map((pack: { id: any; data: () => any; }) => ({ id: pack.id, ...pack.data() }));
                setSearchResult(ps)
                handleOpen(true);
            });

        }
    }

    const senderItemOnClick = (id: string) => unstable_batchedUpdates(() => {

        let pack = searchResult.find((e) => e.id === id)

        setSenderName(pack.senderName)
        setSenderPhone(pack.senderPhone)
        setSenderAddress(pack.senderAddress)
    })
    

    const searchReceiver = (searchtype: string) => {
        let ps: any[] = [];

        if (searchtype === 'receiverName'){
            if(!receiverName)
                return
            
            db.collection("packages").orderBy("receiverName_lowerCase").startAt(receiverName.toLowerCase()).endAt(receiverName.toLowerCase() + "\uf8ff").limit(1000).get().then((data)=>{
                
                ps = data.docs.map((pack: { id: any; data: () => any; }) => ({ id: pack.id, ...pack.data() }));
                setSearchResult(ps)
                handleOpen(false);
            });
        }else{
            if(!(receiverPhoneCode && receiverPhoneNumber))
                return

            db.collection("packages").orderBy("receiverPhone").startAt(receiverPhoneCode.trim() + receiverPhoneNumber.trim()).endAt(receiverPhoneCode.trim() + receiverPhoneNumber.trim() + "\uf8ff").limit(1000).get().then((data)=>{
                
                ps = data.docs.map((pack: { id: any; data: () => any; }) => ({ id: pack.id, ...pack.data() }));
                setSearchResult(ps)
                handleOpen(false);
            });
        }
    }

    const receiverItemOnClick = (id: string) => unstable_batchedUpdates(() =>  {

        let pack = searchResult.find((e) => e.id === id)

        setReceiverName(pack.receiverName)
        setReceiverPhone(pack.receiverPhone)
        setReceiverAddress(pack.receiverAddress)

    })


    return (
        <>

            <Modal isOpen={open} onClose={handleClose}>
                {
                    isSender ?
                    searchResult.map((pack) => <SenderListItem pack={pack} key={pack.id} onClick={senderItemOnClick}/>)
                    :
                    searchResult.map((pack) => <ReceiverListItem pack={pack} key={pack.id} onClick={receiverItemOnClick}/>)
                }
            </Modal>
            <div className="row">
                <div className="col">
                    <ButtonInput id='senderName' state={senderName} setState={setSenderName} label="Илгээгчийн нэр" onClick = {() => searchSender('senderName')}/>
                </div>
                <div className="col">
                    {/* <ButtonPhoneInput id='senderPhone' state={senderPhone} setState={setSenderPhone} label="Илгээгчийн утас" defaultCode="+82" onClick={() => searchSender('senderPhone')}/> */}
                    <ButtonPhoneInput id='senderPhone' state={senderPhone} setState={setSenderPhone} code={senderPhoneCode} number={senderPhoneNumber} setCode={setSenderPhoneCode} setNumber={setSenderPhoneNumber} label="Илгээгчийн утас" defaultCode="+82" onClick = {() => searchSender('senderPhone')}/>
                </div>
            </div>
            

            <div className='row'>
            <div className="col">
                <Textarea value={senderAddress} state={senderAddress} setState={setSenderAddress} label="Илгээгчийн хаяг" />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <ButtonInput id='receiverName' state={receiverName} setState={setReceiverName} label="Хүлээн авагчийн нэр" onClick = {() => searchReceiver('receiverName')}/>
                </div>
                <div className="col">
                    {/* <ButtonPhoneInput id='receiverPhone' state={receiverPhone} setState={setReceiverPhone} label="Хүлээн авагчийн утас" defaultCode="+976" onClick = {() => searchReceiver('receiverPhone')}/> */}
                    <ButtonPhoneInput id='receiverPhone'  state={receiverPhone} setState={setReceiverPhone} code={receiverPhoneCode} number={receiverPhoneNumber} setCode={setReceiverPhoneCode} setNumber={setReceiverPhoneNumber} label="Хүлээн авагчийн утас" defaultCode="+976" onClick = {() => searchReceiver('receiverPhone')}/>
                </div>
            </div>
            <Textarea value ={receiverAddress} state={receiverAddress} setState={setReceiverAddress} label="Хүлээн авагчийн хаяг" />
            <div className="row">
                <div className="col">
                    <Input state={weight} setState={setWeight} label="Жин" type="number" append="кг" />
                </div>
                <div className="col">
                    <Input state={netWeight} setState={setNetWeight} label="Цэвэр жин" type="number" append="кг" />
                </div>				
                <div className="col">
                    <CurrencyInput state={price} setState={setPrice} label="Үнийн дүн" type="number" defaultCode="₩"/>
                </div>
                <div className="col">
                    <Input state={departTime} setState={setDepartTime} label="Гарах огноо" type="date" />
                </div>
            </div>
            <div className="row">
                <div className="col">
                {["worker"].indexOf(currentUser?.role) !== -1 && <>
                    <SelectRemovePaymentMethod state={paymentMethod} setState={setIsPaymentMethod} label="Төлбөрийн мэдээлэл" options={paymentMethods} />
                </>}
                {["admin", "reporter","editor"].indexOf(currentUser?.role) !== -1 && <>
                    <Select state={paymentMethod} setState={setIsPaymentMethod} label="Төлбөрийн мэдээлэл" options={paymentMethods} />
                </>}

                </div>
                <div className="col">
                    <Select state={paymentUtility} setState={setPaymentUtility} label="Төлбөрийн хэрэгсэл" options={paymentUtilities} />
                </div>
                <div className="col">
                    <Select state={hasDelivery} setState={setHasDelivery} label="Монголд хүргэлттэй эсэх" options={deliveryOptions} />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <SelectMode state={mode} setState={setMode} label="Ангилал" options={modes} departTime={departTime} />
                </div>
                <div className="col">
                    <Input state={receiverRegister} setState={setReceiverRegister} label="Хүлээн авагчийн регистр" />
                </div>
                <div className="col">
                    <Select state={status} setState={setStatus} label="Төлөв" options={stats} />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <Input state={desc} setState={setDesc} label="Ачааны мэдээлэл" />
                </div>
                <div className="col">
                    <Input state={value} setState={setValue} label="Ачааны үнэлгээ" />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <Input state={tegbeCode} setState={setTegbeCode} label="Тэгбэ код" />
                </div>
                <div className="col">
                    <Input state={orderCode} setState={setOrderCode} label="Дэс дарааллын код" />
                </div>
            </div>			
            <div className="row">
                <div className="col">
                    <Input type="number" state={quantity} setState={setQuantity} label="Ачааны тоо хэмжээ" />
                </div>
                <div className="col">
                    <Input type="number" state={postfee} setState={setPostfee} label="Хүргэлтийн төлбөр" />
                </div>
            </div>
            <Textarea state={notif} setState={setNotif} label="Тээврийн мэдээлэл" />
            <Link className="btn btn-light" to="/packages">
                <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-caret-left" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" d="M10 12.796L4.519 8 10 3.204v9.592zm-.659.753l-5.48-4.796a1 1 0 0 1 0-1.506l5.48-4.796A1 1 0 0 1 11 3.204v9.592a1 1 0 0 1-1.659.753z" />
                </svg>&nbsp;
                Буцах
            </Link>
            <button className="btn btn-primary float-right" onClick={create}>
                <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-plus-square" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" d="M14 1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                    <path fillRule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                </svg>&nbsp;
                Үүсгэх
            </button>
        </>
    );
}

export default Create;